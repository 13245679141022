import React, { useState } from 'react';
import type {
  FieldValues,
  RegisterOptions,
  UseFormRegisterReturn,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { match } from 'ts-pattern';

import { IconFilter, IconRedirectGreen } from '@/assets';
import { SelectBase } from '@/components';
import { upscaler } from '@/configs/constant';
import FieldItemForm from '@/contents/gen-model/sideBar/custom/FieldItemForm';
import { usePrompt } from '@/provider';
import { useRouter } from '@/provider/RouterProvider';
import TooltipCustom from '@/components/tooltip-custom';

const listItem = [
  [
    {
      label: 'Resize Width',
      key: 'hr_width',
      min: 0,
      max: 2048,
      default: 512,
      step: 1,
      type: 'range',
      tooltip: '',
    },
    {
      label: 'Resize Height',
      key: 'hr_height',
      min: 0,
      max: 2048,
      default: 512,
      step: 1,
      type: 'range',
      tooltip: '',
    },
  ],
  {
    label: 'Upscaler',
    key: 'hr_upscaler',
    min: 0,
    max: 0,
    default: upscaler.map((item) => ({ label: item, value: item })),
    step: 0,
    type: 'select',
    tooltip: 'The method for generating high-definition restored images.',
  },
  [
    {
      label: 'Hires Steps',
      key: 'hr_steps',
      min: 1,
      max: 60,
      default: 20,
      step: 1,
      type: 'range',
      tooltip:
        'A higher number of sampling steps requires more generation time and credits cost. But values over 30 give diminishing returns.',
    },
    {
      label: 'Denoising',
      key: 'hr_denoising_strength',
      min: 0,
      max: 1,
      default: 0.55,
      step: 0.01,
      type: 'range',
      tooltip: 'The intensity of redrawing the image, 0 means no redrawing will be done.',
    },
  ],
];

interface HiresProps {
  watch: UseFormWatch<FieldValues>;
  register: (
    name: string,
    options?: RegisterOptions<FieldValues, string> | undefined,
  ) => UseFormRegisterReturn<string>;
  setValue: UseFormSetValue<FieldValues>;
}

const Hires: React.FC<HiresProps> = ({ watch, setValue, register }) => {
  const [isCustomUpscale, setIsCustomUpscale] = useState(false);
  const [selected, setSelected] = useState('1.5x');
  const { userPlan } = usePrompt();
  const router = useRouter();
  console.log('userPlan', userPlan);

  const handleSelectUpscale = (item: string) => {
    if (
      userPlan < 2
        ? Number(item.split('x')[0]) > 1.5
        : userPlan === 2
        ? Number(item.split('x')[0]) > 2
        : false
    )
      return '';

    if (userPlan < 3 && item === 'Custom') return '';

    setSelected(item);
    if (item === 'Custom') {
      setIsCustomUpscale(true);
      setValue('hr_scale_by', 0);
      setValue('hr_width', 2048);
      setValue('hr_height', 2048);
    } else {
      setValue('hr_scale_by', Number(item.split('x')[0]));
      setValue('hr_width', 0);
      setValue('hr_height', 0);
      setIsCustomUpscale(false);
    }
  };
  console.log(watch('hr_scale_by'));

  const RenderItemFollowType = (item: any) => {
    return match(item.type)
      .with('range', () => (
        <input
          defaultValue={item.default}
          type="range"
          className="h-3 w-full bg-gray-100"
          min={item.min}
          max={item.max}
          step={item.step}
          {...register(item.key)}
        />
      ))
      .with('select', () => (
        <SelectBase
          defaultValue={watch(item.key)}
          onChange={(e) => setValue(item.key, e)}
          list={item.default}
        />
      ))
      .otherwise(() => <></>);
  };

  // useEffect(() => {
  //   setValue('hr_width', 512);
  //   setValue('hr_height', 512);
  // }, []);

  return (
    <div className="mt-6 w-full">
      <div>
        <p className="text-14 font-semibold text-gray-700">
          {match(selected)
            .with('1x', () => 'Upscale by (resize: from 768x1152 to 768x1152)')
            .with('1.5x', () => 'Upscale by (resize: from 768x1152 to 1152x1728)')
            .with('2x', () => 'Upscale by (resize: from 768x1152 to 1536x2304)')
            .with('3x', () => 'Upscale by (resize: from 768x1152 to 2304x3456)')
            .with('4x', () => 'Upscale by (resize: from 768x1152 to 3072x4608)')
            .with('Custom', () => 'Upscale by (resize: from 768x1152 to 5120x5120)')
            .otherwise(() => '')}
        </p>
        <div className="overflow-x-auto scrollbar-hide">
          <div className="flex">
            <div className="mt-3 flex items-center justify-between sm:justify-start gap-2">
            {['1x', '1.5x', '2x', '3x', '4x', 'Custom'].map((item, index) => (
              <TooltipCustom
                isDisabled={
                  item === 'Custom'
                    ? !(userPlan < 3 && item === 'Custom')
                    : !(userPlan < 2
                        ? Number(item.split('x')[0]) > 1.5
                        : userPlan === 2
                        ? Number(item.split('x')[0]) > 2
                        : false)
                }
                showArrow
                content={
                  <span className="max-w-[370px] text-14 font-normal text-white">
                    Upgrade to the Pro version to use the advanced editing tools.
                    <IconRedirectGreen
                      onClick={() => router.push('/pricing')}
                      className="ml-2 inline-block cursor-pointer rounded-full bg-white"
                    />
                  </span>
                }
                classNames={{ base: 'tooltip w-[290px]', arrow: 'arrow-tooltip' }}
              >
                <span
                  aria-checked={item === selected}
                  aria-expanded={item === 'Custom'}
                  aria-disabled={
                    item === 'Custom'
                      ? userPlan < 3 && item === 'Custom'
                      : userPlan < 2
                      ? Number(item.split('x')[0]) > 1.5
                      : userPlan === 2
                      ? Number(item.split('x')[0]) > 2
                      : false
                  }
                  onClick={() => handleSelectUpscale(item)}
                  key={index}
                  className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-[12px] bg-gray-50 text-13 font-semibold aria-checked:border-1 aria-checked:border-gray-500 aria-disabled:bg-gray-50 aria-disabled:text-gray-200 aria-expanded:w-[105px]"
                >
                  {item === 'Custom' && (
                    <IconFilter
                      aria-checked={userPlan < 3}
                      className="m-1 [&>path]:aria-checked:fill-gray-200"
                    />
                  )}
                  {item}
                </span>
              </TooltipCustom>
            ))}
          </div>
          </div>
        </div>
      </div>
      <>
        {isCustomUpscale && listItem[0] && (
          <div className="my-4">
            <FieldItemForm
              RenderItemFollowType={RenderItemFollowType}
              item={listItem[0]}
              watch={watch}
              classLabel="text-gray-700"
              setValue={setValue}
            />
          </div>
        )}
        {listItem.slice(1).map((item, index) => (
          <div key={index} className="my-4">
            <FieldItemForm
              RenderItemFollowType={RenderItemFollowType}
              item={item}
              watch={watch}
              classLabel="text-gray-700"
              setValue={setValue}
            />
          </div>
        ))}
      </>
    </div>
  );
};

export default Hires;
