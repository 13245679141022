import React from 'react';
import type {
  FieldValues,
  RegisterOptions,
  UseFormRegisterReturn,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { match } from 'ts-pattern';

import { SelectBase } from '@/components';
import { upscaler } from '@/configs/constant';
import FieldItemForm from '@/contents/gen-model/sideBar/custom/FieldItemForm';

const listItem = [
  {
    label: 'ADetailer Model',
    key: 'ADetailer Model',
    min: 0,
    max: 0,
    default: upscaler.map((item) => ({ label: item, value: item })),
    step: 0,
    type: 'select',
    tooltip: 'The method for generating high-definition restored images.',
  },
  {
    label: 'Detection model confidence threshold',
    key: 'Detection model confidence threshold',
    min: 0,
    max: 50,
    default: 35,
    step: 1,
    type: 'range',
    tooltip: '',
  },
  [
    {
      label: 'Inpaint mask blur',
      key: 'Inpaint mask blur',
      min: 0,
      max: 50,
      default: 35,
      step: 1,
      type: 'range',
      tooltip: '',
    },
    {
      label: 'Denoising',
      key: 'Denoising',
      min: 0,
      max: 15,
      default: 7.5,
      step: 0.1,
      type: 'range',
      tooltip: '',
    },
  ],
];

interface ADetailerProps {
  watch: UseFormWatch<FieldValues>;
  register: (
    name: string,
    options?: RegisterOptions<FieldValues, string> | undefined,
  ) => UseFormRegisterReturn<string>;
  setValue: UseFormSetValue<FieldValues>;
}

const ADetailer: React.FC<ADetailerProps> = ({ watch, setValue, register }) => {
  const RenderItemFollowType = (item: any) => {
    return match(item.type)
      .with('range', () => (
        <input
          defaultValue={item.default}
          type="range"
          className="h-3 w-full bg-gray-100"
          min={item.min}
          max={item.max}
          step={item.step}
          {...register(item.key)}
        />
      ))
      .with('select', () => (
        <SelectBase
          defaultValue={watch(item.key)}
          onChange={(e) => setValue(item.key, e)}
          list={item.default}
        />
      ))
      .otherwise(() => <></>);
  };

  return (
    <div className="mt-6 w-full">
      <>
        {listItem.map((item, index) => (
          <div key={index} className="my-4">
            <FieldItemForm
              RenderItemFollowType={RenderItemFollowType}
              item={item}
              watch={watch}
              classLabel="text-gray-700"
              setValue={setValue}
            />
          </div>
        ))}
      </>
    </div>
  );
};

export default ADetailer;
