import React from 'react';
import type { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { ArrowRight2020Icon, Trash1414Icon } from '@/assets';
import { Images } from '@/components';

interface LoraModelProps {
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setOptionsSelectStyle?: React.Dispatch<React.SetStateAction<string>>;
}

const LoraModel: React.FC<LoraModelProps> = ({ setValue, setOptionsSelectStyle, watch }) => {
  const handleRemoveLora = (lora: any) => {
    const rest = watch('lora').filter((item: any) => item.alias !== lora.alias);
    setValue('lora', rest);
  };

  const handleChangeValue = (value: any, lora: any) => {
    const rest = watch('lora').map((item: any) =>
      item.alias === lora.alias ? { ...item, value: Number(value) } : item,
    );
    setValue('lora', rest);
  };

  return (
    <div>
      {watch('lora')?.length > 0 &&
        watch('lora').map((item: any) => (
          <div className="relative mb-2 flex h-[63px] w-full items-center rounded-[12px] bg-gray-50 p-2">
            <p className="absolute left-0 top-0 z-99 flex h-[17px] w-[38px] items-center justify-center rounded-br-[20px] rounded-tl-[20px] bg-gray-200 text-[8px] font-normal">
              LoRA
            </p>
            <div className="h-[44px] w-[44px] rounded-[8px]">
              <Images
                loading="eager"
                src={item?.preview[0] ?? ''}
                alt="images select"
                imageWrapper="rounded-[8px]"
                className="h-[44px] w-[44px] rounded-[8px]"
              />
            </div>
            <div className="ml-2 flex flex-1 flex-col">
              <div className="flex justify-between">
                <span className="line-clamp-2 w-[calc(100%-56px)] break-words text-14 font-semibold">
                  {item.alias || 'Select lora'}
                </span>
                <Trash1414Icon onClick={() => handleRemoveLora(item)} className="cursor-pointer" />
              </div>
              <div className="flex items-center justify-between">
                <input
                  value={item.value}
                  defaultValue={1}
                  type="range"
                  className="h-3 w-[70%] bg-gray-100"
                  min={-2}
                  max={2}
                  step={0.1}
                  onChange={(e) => handleChangeValue(e.target.value, item)}
                />
                <div className="flex items-center rounded-[8px] bg-white">
                  <p className="px-[6px] py-1">{item.value}</p>
                  <p
                    onClick={() =>
                      Number(item.value) < 2 &&
                      handleChangeValue(Math.round((item.value + 0.1) * 100) / 100, item)
                    }
                    className="cursor-pointer px-[6px] py-1"
                  >
                    +
                  </p>
                  <p
                    onClick={() =>
                      Number(item.value) > -2 &&
                      handleChangeValue(Math.round((item.value - 0.1) * 100) / 100, item)
                    }
                    className="cursor-pointer px-[6px] py-1"
                  >
                    -
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      <div
        onClick={() => setOptionsSelectStyle && setOptionsSelectStyle('lora')}
        className="flex cursor-pointer justify-between rounded-[12px] bg-gray-50 p-2"
      >
        <span className="text-14 font-semibold">Add LoRA</span>
        <ArrowRight2020Icon />
      </div>
    </div>
  );
};

export default LoraModel;
