import { Checkbox, Divider, Input, Switch } from '@nextui-org/react';
import React from 'react';
import type {
  FieldValues,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegisterReturn,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { match } from 'ts-pattern';

import {
  DiceIcon,
  IconRedirectGreen,
  IconSize11,
  IconSize23,
  IconSize32,
  IconSize34,
  IconSize43,
  IconSize169,
  IconSize916,
  IconSizeCustom,
} from '@/assets';
import { SelectBase } from '@/components';
import { RatioOfModel, SizeOfModel } from '@/configs/constant';
import { onlyOneBatchSizeInpaint, sizeSdxl3, sizeText2Image } from '@/configs/constant';
import type { TypeOfModelOptions } from '@/configs/optionsModel';
import FieldItemForm from '@/contents/gen-model/sideBar/custom/FieldItemForm';
import { Hires } from '@/contents/gen-model/sideBar/form';
import LoraModel from '@/contents/gen-model/sideBar/modal/LoraModel';
import ModelText2Image from '@/contents/gen-model/sideBar/modal/ModelText2Image';
import { usePrompt } from '@/provider';
import { useRouter } from '@/provider/RouterProvider';
import { useAuth } from '@/provider/UserProvider';
import TooltipCustom from '@/components/tooltip-custom';

interface OptionText2Props {
  watch: UseFormWatch<FieldValues>;
  item: TypeOfModelOptions | TypeOfModelOptions[];
  register: (
    name: string,
    options?: RegisterOptions<FieldValues, string> | undefined,
  ) => UseFormRegisterReturn<string>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setOptionsSelectStyle?: React.Dispatch<React.SetStateAction<string>>;
  sizeModel?: SizeOfModel;
  borderLess?: boolean;
  borderLessMarginTop?: boolean;
  ratioModel?: RatioOfModel;
}

const OptionText2: React.FC<OptionText2Props> = ({
  watch,
  item,
  register,
  setValue,
  setOptionsSelectStyle,
  getValues,
  sizeModel = sizeText2Image,
  ratioModel = sizeSdxl3,
  borderLess,
  borderLessMarginTop,
}) => {
  const { isLogin } = useAuth();
  const { userPlan } = usePrompt();
  const router = useRouter();
  const RenderItemFollowType = (item: TypeOfModelOptions) => {
    const handleHeightChange = (value: any) => {
      let numValue = Number(value);
      if (numValue < 0 || numValue > 1024) {
        numValue = numValue > 1024 ? 1024 : 0;
      }
      setValue('height', numValue, { shouldValidate: true });
    };
    const handleWidthChange = (value: any) => {
      let numValue = Number(value);
      if (numValue < 0 || numValue > 1024) {
        numValue = numValue > 1024 ? 1024 : 0;
      }
      setValue('width', numValue, { shouldValidate: true });
    };

    return match(item.type)
      .with('range', () => (
        <input
          defaultValue={item.default}
          type="range"
          className="h-3 w-full bg-gray-100"
          min={item.min}
          max={item.max}
          step={item.step}
          {...register(item.key)}
        />
      ))
      .with('model', () => (
        <ModelText2Image setOptionsSelectStyle={setOptionsSelectStyle} watch={watch} />
      ))
      .with('lora', () => (
        <LoraModel
          setOptionsSelectStyle={setOptionsSelectStyle}
          setValue={setValue}
          watch={watch}
        />
      ))
      .with('select', () => (
        <SelectBase
          defaultValue={watch(item.key)}
          onChange={(e) => setValue(item.key, e)}
          list={item.default}
        />
      ))
      .with('switch', () => (
        <>
          <Switch
            classNames={{
              base: 'h-[22px]',
              wrapper: 'w-[44px] h-[22px] bg-gray-300 px-[2px] group-data-[selected=true]:bg-black',
              thumb: 'group-data-[selected=true]:ml-[19px]',
            }}
            className="mr-3"
            isSelected={watch(item.key)}
            defaultSelected={watch(item.key)}
            onValueChange={(e) => setValue(item.key, e)}
          />
        </>
      ))
      .with('checkbox', () => (
        <div className="flex w-full items-center justify-end">
          <Checkbox
            defaultSelected={watch(item.key)}
            onValueChange={(e) => setValue(item.key, e)}
            className="h-full"
            radius="sm"
          />
          <span className="text-14 font-normal">SDXL Refiner</span>
        </div>
      ))
      .with('text-area', () => (
        <textarea
          defaultValue=""
          className="min-h-[149px] w-full resize-none rounded-[16px] border-none bg-gray-50 px-4 py-[14px] outline-none"
          placeholder="longbody, lowres, bad anatomy, bad hands, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality"
          {...register(item.key)}
        />
      ))
      .with('inputNumber', () => (
        <Input
          type="number"
          classNames={{
            input: ['font-normal text-14 pl-1', 'rounded-[100px]'],
            innerWrapper: ['bg-transparent', 'rounded-[100px]'],
            inputWrapper: ['bg-gray-50', 'rounded-[100px]'],
          }}
          endContent={
            <DiceIcon
              onClick={() =>
                setValue(item.key, Math.floor(Math.random() * (4294967295 - 1 + 1)) + 1)
              }
              className="cursor-pointer"
            />
          }
          {...register(item.key)}
        />
      ))
      .with('selectText', () => (
        <div className="flex flex-wrap gap-2">
          {item.default?.map((value: any, index: number) => (
            <span
              aria-disabled={!isLogin}
              aria-checked={
                onlyOneBatchSizeInpaint.includes(getValues('inpaint_area')) &&
                item.key === 'batch_size' &&
                index !== 0
              }
              aria-expanded={watch(item.key) === value}
              className="min-w-[34px] cursor-pointer rounded-full border border-gray-100 px-3 py-[6px] text-13 font-medium text-gray-500 aria-checked:hidden aria-disabled:cursor-not-allowed aria-expanded:border-black aria-expanded:text-black"
              key={index}
              onClick={() =>
                isLogin
                  ? setValue(item.key, value)
                  : toast.warning('You must login to select option!')
              }
            >
              {value}
            </span>
          ))}
        </div>
      ))
      .with('selectNumberImg', () => (
        <div className="flex flex-wrap gap-2">
          {item.default?.map((value: any, index: number) => (
            <TooltipCustom
              isDisabled={!(userPlan < 2 ? value > 1 : userPlan === 2 ? value > 2 : false)}
              showArrow
              content={
                <span className="max-w-[370px] text-14 font-normal text-white">
                  Upgrade to the Pro version to use the advanced editing tools.
                  <IconRedirectGreen
                    onClick={() => router.push('/pricing')}
                    className="ml-2 inline-block cursor-pointer rounded-full bg-white"
                  />
                </span>
              }
              classNames={{ base: 'tooltip w-[290px]', arrow: 'arrow-tooltip' }}
            >
              <span
                aria-expanded={watch(item.key) === value}
                aria-checked={userPlan < 2 ? value > 1 : userPlan === 2 ? value > 2 : false}
                className="min-w-[34px] cursor-pointer rounded-full border border-gray-100 px-3 py-[6px] text-13 font-medium text-gray-500 aria-checked:select-none aria-checked:text-gray-100 aria-checked:disabled:cursor-not-allowed aria-expanded:border-black aria-expanded:text-black"
                key={index}
                onClick={() => {
                  if (!(userPlan < 2 ? value > 1 : userPlan === 2 ? value > 2 : false))
                    setValue(item.key, value);
                  else return '';
                }}
              >
                {value}
              </span>
            </TooltipCustom>
          ))}
        </div>
      ))
      .with('size', () => (
        <div>
          <div className="grid grid-cols-4 gap-2">
            {sizeModel.map((value, index) => (
              <div
                key={index}
                aria-expanded={watch(item.key) === value.text}
                className="flex cursor-pointer flex-col items-center rounded-[12px] border bg-gray-50 px-2 py-3 aria-expanded:border-black"
                onClick={() => setValue(item.key, value.text)}
              >
                <span className="mb-2 text-center">{iconOfRatio[value.ratio]}</span>
                <span className="text-12 font-semibold">{value.shape}</span>
                <span className="text-[10px] font-normal text-gray-500 text-center leading-[9px]">
                  {value.text}
                </span>
              </div>
            ))}
          </div>
          {watch(item.key) === 'custom size' && (
            <div className="mt-3 flex items-center gap-3">
              <div className="flex w-[50%] flex-col">
                <div className="flex w-full items-center justify-between">
                  <span className="text-14 font-semibold">Height</span>
                  <input
                    type="number"
                    {...register('height', {
                      valueAsNumber: true,
                      min: '0',
                      max: '1024',
                    })}
                    min="0"
                    max="1024"
                    value={watch('height')}
                    onChange={(e) => handleHeightChange(e.target.value)}
                    className="flex h-[34px] w-[55px] items-center justify-center rounded-[8px] bg-gray-50 pl-2 text-14 font-normal"
                  />
                </div>
                <input
                  type="range"
                  {...register('height', {
                    valueAsNumber: true,
                    min: '0',
                    max: '1024',
                  })}
                  min="0"
                  max="1024"
                  value={watch('height')}
                  onChange={(e) => handleHeightChange(e.target.value)}
                  className="mt-3 h-3 w-full bg-gray-100"
                />
              </div>
              <div className="flex w-[50%] flex-col">
                <div className="flex w-full items-center justify-between">
                  <span className="text-14 font-semibold">Width</span>
                  <input
                    type="number"
                    {...register('width', {
                      valueAsNumber: true,
                      min: '0',
                      max: '1024',
                    })}
                    min="0"
                    max="1024"
                    value={watch('width')}
                    onChange={(e) => handleWidthChange(e.target.value)}
                    className="flex h-[34px] w-[55px] items-center justify-center rounded-[8px] bg-gray-50 pl-2 text-14 font-normal"
                  />
                </div>
                <input
                  type="range"
                  {...register('width', {
                    valueAsNumber: true,
                    min: '0',
                    max: '1024',
                  })}
                  min="0"
                  max="1024"
                  value={watch('width')}
                  onChange={(e) => handleWidthChange(e.target.value)}
                  className="mt-3 h-3 w-full bg-gray-100"
                />
              </div>
            </div>
          )}
        </div>
      ))
      .with('ratio', () => (
        <div>
          <div className="grid grid-cols-4 gap-2">
            {ratioModel.map((value, index) => (
              <div
                key={index}
                aria-expanded={watch(item.key) === value.ratio_value}
                className="flex cursor-pointer flex-col items-center rounded-[12px] border bg-gray-50 px-2 py-3 aria-expanded:border-black"
                onClick={() => setValue(item.key, value.ratio_value)}
              >
                <span className="text-center text-14 font-normal text-gray-500">{value.ratio}</span>
                <span className="text-12 font-semibold">{value.shape}</span>
              </div>
            ))}
          </div>
        </div>
      ))
      .otherwise(() => <></>);
  };

  return (
    <div
      aria-disabled={!isLogin && (item as any)?.key !== 'negative_prompt'}
      className="w-full aria-disabled:pointer-events-none aria-disabled:select-none aria-disabled:opacity-25	"
    >
      <div
        aria-expanded={!Array.isArray(item) && item?.placement === 'horizontal'}
        className="aria-expanded:flex aria-expanded:items-center aria-expanded:justify-between"
      >
        <FieldItemForm
          RenderItemFollowType={RenderItemFollowType}
          item={item}
          watch={watch}
          setValue={setValue}
        />
      </div>
      <div>
        {!Array.isArray(item) && item.key === 'enable_hires' && watch(item.key) === true && (
          <Hires register={register} setValue={setValue} watch={watch} />
        )}
        {/* {!Array.isArray(item) && item.key === 'adetailer' && watch(item.key) !== true && (
          <ADetailer register={register} setValue={setValue} watch={watch} />
        )} */}
      </div>
      {borderLess && (
        <Divider
          aria-checked={Boolean(borderLessMarginTop)}
          className="mt-5 w-full bg-gray-100 aria-checked:mb-4"
        />
      )}
    </div>
  );
};

export default OptionText2;

export const iconOfRatio: any = {
  '[1:1]': <IconSize11 />,
  '[3:4]': <IconSize34 />,
  '[2:3]': <IconSize23 />,
  '[9:16]': <IconSize916 />,
  '[4:3]': <IconSize43 />,
  '[3:2]': <IconSize32 />,
  '[16:9]': <IconSize169 />,
  custom: <IconSizeCustom />,
};
