import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

interface UseViewMoreLessProps {
  maxLines?: number;
  defaultExpanded?: boolean;
}

interface UseViewMoreLessResult {
  expanded: boolean;
  shouldShowButton: boolean;
  toggleExpanded: () => void;
  textRef: React.RefObject<HTMLDivElement>;
}

const useViewMoreLess = ({
  maxLines = 2,
  defaultExpanded = false,
}: UseViewMoreLessProps): UseViewMoreLessResult => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const textRef = useRef<HTMLDivElement>(null);
  const [shouldShowButton, setShouldShowButton] = useState(false);

  useEffect(() => {
    if (!textRef.current) return;
    const calculateLines = () => {
      if (textRef.current) {
        const lineHeight = parseFloat(getComputedStyle(textRef.current).lineHeight || '0');
        const lines = textRef.current.getBoundingClientRect().height / lineHeight;
        console.log(
          '🐉 ~ calculateLines ~ textRef.current.getBoundingClientRect().height ~ 🚀\n',
          textRef.current.getBoundingClientRect().height,
        );
        console.log('🐉 ~ calculateLines ~ lineHeight ~ 🚀\n', lineHeight);
        return lines;
      }
      return 0;
    };

    const handleResize = () => {
      const lines = calculateLines();
      console.log('🐉 ~ handleResize ~ lines ~ 🚀\n', lines);
      const shouldShowButton = lines > maxLines;
      console.log('🐉 ~ handleResize ~ shouldShowButton ~ 🚀\n', shouldShowButton);
      setShouldShowButton(shouldShowButton);
      if (shouldShowButton) {
        textRef.current?.classList.add(`line-clamp-${maxLines}`);
      } else {
        textRef.current?.classList.remove(`line-clamp-${maxLines}`);
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', debounce(handleResize, 500)); // Recalculate on window resize

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [maxLines, textRef]);

  const toggleExpanded = () => {
    if (textRef.current) {
      if (!expanded) {
        textRef.current.classList.remove(`line-clamp-${maxLines}`);
      } else {
        textRef.current.classList.add(`line-clamp-${maxLines}`);
      }
    }
    setExpanded((prev) => !prev);
  };

  return { expanded, toggleExpanded, textRef, shouldShowButton };
};

export default useViewMoreLess;
