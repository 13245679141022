import { magic_wand } from '@/assets';
import Lottie from 'lottie-react';
import React, { useEffect, useRef, useState } from 'react';

interface LoadingGeneratingProps {
  noPercentage?: boolean;
  isRetry?: boolean;
  classString?: string;
}

const LoadingGenerating: React.FC<LoadingGeneratingProps> = ({
  noPercentage = false,
  isRetry = false,
  classString,
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const timeInterval = useRef<any>();

  useEffect(() => {
    setCurrentValue(0);
    timeInterval.current = setInterval(() => {
      setCurrentValue((currentValue) => currentValue + 2);
    }, 350);

    return () => {
      clearInterval(timeInterval.current);
      setCurrentValue(0);
    };
  }, []);

  useEffect(() => {
    if (currentValue > 80) {
      clearInterval(timeInterval.current);
    }
  }, [currentValue]);

  return (
    <div
      className={`bg-generating h-[220px] flex flex-col items-center ${
        classString ? classString : ''
      }`}
    >
      {/* <MagicWandGeneratingIcon className="mt-[50px]" /> */}
      <Lottie className="h-[130px]" animationData={magic_wand} loop />
      <p className="text-white text-[24px] font-semibold">{!noPercentage && `${currentValue}%`}</p>
      <p className="text-white text-[16px] font-semibold">
        {isRetry ? 'Retry...' : 'Generating...'}
      </p>
    </div>
  );
};

export default LoadingGenerating;
