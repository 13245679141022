import {
  controlModuleIPAdapter,
  controlTypeIPAdapter,
  embedding,
  inpaintArea,
  inpaintMaskContent,
  inpaintMaskMode,
  listSampler,
  vae,
} from '@/configs/constant';

export const modelOptions = {
  label: 'Model',
  key: 'model',
  min: 0,
  max: 0,
  default: 0,
  step: 0,
  type: 'model',
  tooltip:
    'Choose a model to have a unique style. Each model is created with distinct characteristics using different data.',
};

export type TypeOfModelOptions = Omit<typeof modelOptions, 'default'> & {
  default: any;
  placement?: string;
  borderLess?: boolean;
  positionLogin?: boolean;
};

export const generateTypeChatGenerator = {
  chatGeneratorOption: {
    name: 'chatGeneratorOption',
    options: [
      {
        label: 'Size',
        key: 'size',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'size',
        tooltip: '',
        // borderLess:true,
      },
      // {
      //   label: 'Number of images',
      //   key: 'batch_size',
      //   min: 0,
      //   max: 0,
      //   default: [1, 2, 3, 4],
      //   step: 0,
      //   type: 'selectNumberImg',
      //   tooltip: '',
      // },
    ],
  },
};

export const generateTypeText2ModelsEnable = {
  text2option: {
    name: 'Text2option',
    options: [
      {
        label: 'Negative Prompt',
        key: 'negative_prompt',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'text-area',
        tooltip: '',
        borderLess: true,
      },
      {
        label: 'Model',
        key: 'model',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'model',
        tooltip:
          'Choose a model to have a unique style. Each model is created with distinct characteristics using different data.',
        positionLogin: true,
      },
      {
        label: 'Lora',
        key: 'lora',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'lora',
        tooltip: '',
      },
      {
        label: 'Add Embedding',
        key: 'embedding',
        min: 0,
        max: 0,
        default: embedding.map((item) => ({ label: item, value: item })),
        step: 0,
        type: 'select',
        tooltip: '',
      },
      {
        label: 'VAE',
        key: 'vae',
        min: 0,
        max: 0,
        default: vae.map((item) => ({ label: item, value: item })),
        step: 0,
        type: 'select',
        tooltip: '',
      },
      {
        label: 'Sampler',
        key: 'sampler',
        min: 0,
        max: 0,
        default: listSampler.map((item) => ({ label: item, value: item })),
        step: 0,
        type: 'select',
        tooltip: '',
      },
      [
        {
          label: 'Steps',
          key: 'steps',
          min: 0,
          max: 50,
          default: 35,
          step: 1,
          type: 'range',
          tooltip: 'The number of steps to capture an image',
        },
        {
          label: 'CFG scale',
          key: 'cfg_scale',
          min: 0,
          max: 15,
          default: 7.5,
          step: 0.1,
          type: 'range',
          tooltip:
            "It dictates how closely the generated image mirrors a user's prompt or input image.",
        },
      ],
      {
        label: 'Seed',
        key: 'seed',
        min: 0,
        max: 4294967295,
        default: 35,
        step: 1,
        type: 'inputNumber',
        tooltip: 'Seed numbers are generated randomly for each image but can be specified',
      },
      {
        label: 'Number of images',
        key: 'batch_size',
        min: 0,
        max: 0,
        default: [1, 2, 3, 4],
        step: 0,
        type: 'selectNumberImg',
        tooltip: '',
      },
      {
        label: 'Size',
        key: 'size',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'size',
        tooltip: '',
      },
      {
        label: 'Enable hires',
        key: 'enable_hires',
        min: 0,
        max: 0,
        default: 1,
        step: 0,
        type: 'switch',
        tooltip: 'More details and higher quality.',
        placement: 'horizontal',
        borderLess: true,
      },
      {
        label: 'ADetailer',
        key: 'adetailer',
        min: 0,
        max: 0,
        default: 1,
        step: 0,
        type: 'switch',
        tooltip:
          'The optimal solution for facial collapse, it also improves hand deformities to some extent.',
        placement: 'horizontal',
      },
    ],
  },
};

export const generateTypeSticker = {
  stickerOption: {
    name: 'stickerOption',
    options: [
      {
        label: 'Negative Prompt',
        key: 'negative_prompt',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'text-area',
        tooltip: '',
      },
      {
        label: 'Number of images',
        key: 'batch_size',
        min: 0,
        max: 0,
        default: [1, 2, 3, 4],
        step: 0,
        type: 'selectNumberImg',
        tooltip: '',
        positionLogin: true,
      },
      {
        label: 'Control weight',
        key: 'control_weight',
        min: 0,
        max: 2,
        default: 1,
        step: 0.01,
        type: 'range',
        tooltip:
          'Control weight: The lower the weight, the less ControlNet demands that the image follow the control map.',
      },
    ],
  },
};

export const generateTypeSdxl = {
  text2option: {
    name: 'Text2option',
    options: [
      {
        label: 'Lora',
        key: 'lora',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'lora',
        tooltip: '',
        borderLess: true,
        positionLogin: true,
      },
      {
        label: 'VAE',
        key: 'vae',
        min: 0,
        max: 0,
        default: vae.map((item) => ({ label: item, value: item })),
        step: 0,
        type: 'select',
        tooltip: '',
      },
      {
        label: 'Negative Prompt',
        key: 'negative_prompt',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'text-area',
        tooltip: '',
      },
      {
        label: 'Sampler',
        key: 'sampler',
        min: 0,
        max: 0,
        default: listSampler.map((item) => ({ label: item, value: item })),
        step: 0,
        type: 'select',
        tooltip: '',
      },
      [
        {
          label: 'Steps',
          key: 'steps',
          min: 0,
          max: 50,
          default: 35,
          step: 1,
          type: 'range',
          tooltip: 'The number of steps to capture an image',
        },
        {
          label: 'CFG scale',
          key: 'cfg_scale',
          min: 0,
          max: 15,
          default: 7.5,
          step: 0.1,
          type: 'range',
          tooltip:
            "It dictates how closely the generated image mirrors a user's prompt or input image.",
        },
      ],
      {
        label: 'Seed',
        key: 'seed',
        min: 0,
        max: 4294967295,
        default: 35,
        step: 1,
        type: 'inputNumber',
        tooltip: 'Seed numbers are generated randomly for each image but can be specified',
      },
      {
        label: 'Number of images',
        key: 'batch_size',
        min: 0,
        max: 0,
        default: [1, 2, 3, 4],
        step: 0,
        type: 'selectNumberImg',
        tooltip: '',
      },
      {
        label: 'Size',
        key: 'size',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'size',
        tooltip: '',
      },
      {
        label: 'Enable hires',
        key: 'enable_hires',
        min: 0,
        max: 0,
        default: 1,
        step: 0,
        type: 'switch',
        tooltip: '',
        placement: 'horizontal',
      },
    ],
  },
};

export const generateTypeInpainting = {
  inpaintOption: {
    name: 'inpaintOption',
    options: [
      {
        label: 'Negative prompt',
        key: 'negative_prompt',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'text-area',
        tooltip: '',
        borderLess: true,
      },
      {
        label: 'Sampler',
        key: 'sampler',
        min: 0,
        max: 0,
        default: listSampler.map((item) => ({ label: item, value: item })),
        step: 0,
        type: 'select',
        tooltip: '',
        positionLogin: true,
      },
      [
        {
          label: 'Steps',
          key: 'steps',
          min: 0,
          max: 50,
          default: 25,
          step: 1,
          type: 'range',
          tooltip: 'The number of steps to capture an image',
        },
        {
          label: 'CFG scale',
          key: 'cfg_scale',
          min: 0,
          max: 15,
          default: 7.5,
          step: 0.1,
          type: 'range',
          tooltip:
            "It dictates how closely the generated image mirrors a user's prompt or input image.",
        },
      ],
      {
        label: 'Seed',
        key: 'seed',
        min: 0,
        max: 4294967295,
        default: 35,
        step: 1,
        type: 'inputNumber',
        tooltip: 'Seed numbers are generated randomly for each image but can be specified',
      },
      {
        label: 'Denoising Strength',
        key: 'denoising_strength',
        min: 0,
        max: 1,
        default: 0.75,
        step: 0.01,
        type: 'range',
        tooltip: 'The intensity of redrawing the image, 0 means no redrawing will be done.',
      },
      {
        label: 'Mask mode',
        key: 'inpaint_mask_mode',
        min: 0,
        max: 0,
        default: inpaintMaskMode,
        step: 0,
        type: 'selectText',
        tooltip: '',
      },
      {
        label: 'Mask content',
        key: 'inpaint_mask_content',
        min: 0,
        max: 0,
        default: inpaintMaskContent,
        step: 0,
        type: 'selectText',
        tooltip: '',
      },
      {
        label: 'Area',
        key: 'inpaint_area',
        min: 0,
        max: 0,
        default: inpaintArea,
        step: 0,
        type: 'selectText',
        tooltip: '',
      },
      {
        label: 'Number of images',
        key: 'batch_size',
        min: 0,
        max: 0,
        default: [1, 2, 3, 4],
        step: 0,
        type: 'selectNumberImg',
        tooltip: '',
      },
    ],
  },
};

export const generateTypeAnime = {
  AnimeOption: {
    name: 'animeOption',
    options: [
      {
        label: 'Negative Prompt',
        key: 'negative_prompt',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'text-area',
        tooltip: '',
        borderLess: true,
      },
      {
        label: 'Model',
        key: 'model',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'model',
        tooltip:
          'Choose a model to have a unique style. Each model is created with distinct characteristics using different data.',
        positionLogin: true,
      },
      {
        label: 'Lora',
        key: 'lora',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'lora',
        tooltip: '',
      },
      {
        label: 'Control weight',
        key: 'control_weight',
        min: 0,
        max: 2,
        default: 1,
        step: 0.01,
        type: 'range',
        tooltip:
          'Control weight: The lower the weight, the less ControlNet demands that the image follow the control map.',
      },
      {
        label: 'Number of images',
        key: 'batch_size',
        min: 0,
        max: 0,
        default: [1, 2, 3, 4],
        step: 0,
        type: 'selectNumberImg',
        tooltip: '',
      },
    ],
  },
};

export const generateIpAdapter = {
  controlOption: {
    name: 'ControlOption',
    options: [
      {
        label: 'Negative Prompt',
        key: 'negative_prompt',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'text-area',
        tooltip: '',
        borderLess: true,
      },
      {
        label: 'Control Type',
        key: 'controlType',
        min: 0,
        max: 0,
        default: controlTypeIPAdapter.map((item) => ({ label: item, value: item })),
        step: 0,
        type: 'select',
        tooltip: '',
        positionLogin: true,
      },
      {
        label: 'Control Module',
        key: 'control_module',
        min: 0,
        max: 0,
        default: controlModuleIPAdapter.map((item) => ({ label: item, value: item })),
        step: 0,
        type: 'select',
        tooltip: '',
      },
    ],
  },
};

export const generateSDXL3 = {
  controlOption: {
    name: 'ControlOption',
    options: [
      {
        label: 'Negative Prompt',
        key: 'negative_prompt',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'text-area',
        tooltip: '',
        borderLess: true,
      },
      {
        label: 'Model',
        key: 'model',
        min: 0,
        max: 0,
        default: [
          { label: 'sd3', value: 'sd3' },
          { label: 'sd3-turbo', value: 'sd3-turbo' },
        ],
        step: 0,
        type: 'select',
        tooltip: '',
        positionLogin: true,
      },
      {
        label: 'Seed',
        key: 'seed',
        min: 0,
        max: 4294967295,
        default: 35,
        step: 1,
        type: 'inputNumber',
        tooltip: 'Seed numbers are generated randomly for each image but can be specified',
      },
      {
        label: 'Size',
        key: 'size',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'ratio',
        tooltip: '',
      },
    ],
  },
};

export const generateTypeControlEnable = {
  controlOption: {
    name: 'ControlOption',
    options: [
      {
        label: 'Lora',
        key: 'lora',
        min: 0,
        max: 0,
        default: 0,
        step: 0,
        type: 'lora',
        tooltip: '',
        positionLogin: true,
      },
      [
        {
          label: 'Steps',
          key: 'steps',
          min: 0,
          max: 50,
          default: 30,
          step: 1,
          type: 'range',
          tooltip:
            'The number of steps to capture an image. Keeping the number of steps between 20 and 40 is the best.',
        },
        {
          label: 'Cfg scale',
          key: 'cfg_scale',
          min: 0,
          max: 15,
          default: 7.5,
          step: 0.1,
          type: 'range',
          tooltip:
            "Cfg scale: It dictates how closely the generated image mirrors a user's prompt or input image.",
        },
      ],
      {
        label: 'Control weight',
        key: 'control_weight',
        min: 0,
        max: 2,
        default: 1,
        step: 0.01,
        type: 'range',
        tooltip:
          'Control weight: The lower the weight, the less ControlNet demands that the image follow the control map.',
      },
      {
        label: 'Seed',
        key: 'seed',
        min: 0,
        max: 4294967295,
        default: 35,
        step: 1,
        type: 'inputNumber',
        tooltip: 'Seed numbers are generated randomly for each image but can be specified',
      },
    ],
  },
};
