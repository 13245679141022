import React from 'react';
import type { FieldValues, UseFormWatch } from 'react-hook-form';

import { ArrowRight2020Icon } from '@/assets';
import { Images } from '@/components';
import { useSQuery } from '@/utils';

interface ModelText2ImageProps {
  watch: UseFormWatch<FieldValues>;
  setOptionsSelectStyle?: React.Dispatch<React.SetStateAction<string>>;
}

const ModelText2Image: React.FC<ModelText2ImageProps> = ({ setOptionsSelectStyle, watch }) => {
  const { data: dataListModels } = useSQuery({
    queryKey: ['listMode'],
  });

  return (
    <div
      onClick={() => setOptionsSelectStyle && setOptionsSelectStyle('model')}
      className="relative flex h-[60px] w-full cursor-pointer items-center justify-between rounded-[12px] bg-gray-50 p-2"
    >
      <p className="absolute left-0 top-0 z-99 flex h-[17px] w-[85px] items-center justify-center rounded-br-[20px] rounded-tl-[20px] bg-gray-200 text-[8px] font-normal">
        Basic Model
      </p>
      <div className="flex flex-1 items-center">
        <div className="h-[44px] w-[44px] rounded-[8px]">
          <Images
            loading="eager"
            src={
              (dataListModels as any)?.data?.find((item: any) => item.value === watch('model'))
                ?.image
            }
            alt="images select"
            imageWrapper="rounded-[8px]"
            className="h-[44px] w-[44px] rounded-[8px] object-cover object-top"
          />
        </div>
        <span className="ml-3 line-clamp-2 w-[calc(100%-56px)] break-words text-14 font-semibold">
          {(dataListModels as any)?.data?.find((item: any) => item.value === watch('model'))?.label}
        </span>
      </div>
      <ArrowRight2020Icon />
    </div>
  );
};

export default ModelText2Image;
