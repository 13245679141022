import { Input } from '@nextui-org/react';
import React from 'react';
import type { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { InfoGenIcon } from '@/assets';
import type { TypeOfModelOptions } from '@/configs/optionsModel';
import TooltipCustom from '@/components/tooltip-custom';

interface FieldItemFormProps {
  watch: UseFormWatch<FieldValues>;
  item: TypeOfModelOptions | TypeOfModelOptions[];
  RenderItemFollowType: (item: TypeOfModelOptions) => React.JSX.Element;
  classLabel?: string;
  setValue: UseFormSetValue<FieldValues>;
}

const FieldItemForm: React.FC<FieldItemFormProps> = ({
  watch,
  item,
  RenderItemFollowType,
  classLabel = '',
  setValue,
}) => {
  return (
    <>
      {Array.isArray(item) ? (
        <div className="flex items-center gap-3">
          <div className="w-1/2">
            <div className="mb-2 flex items-center justify-between">
              <div className="flex items-center">
                <span className={`text-14 font-semibold sm:text-12 ${classLabel}`}>
                  {item[0]?.label}
                </span>
                {item[0]?.tooltip && (
                  <TooltipCustom
                    showArrow
                    content={
                      <span className="max-w-[370px] text-13 font-normal text-white">
                        {item[0]?.tooltip}
                      </span>
                    }
                    classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                  >
                    <span className="ml-2">
                      <InfoGenIcon />
                    </span>
                  </TooltipCustom>
                )}
              </div>
              {item[0]?.type === 'range' && (
                <Input
                  step={item[0].step}
                  value={watch(item[0]?.key)}
                  defaultValue={watch(item[0]?.key)}
                  onChange={(e) => item[0]?.key && setValue(item[0]?.key, e.target.value)}
                  type="number"
                  classNames={{
                    base: 'w-[30%]',
                    input: ['font-normal sm:text-12 text-14 pl-1', 'rounded-[100px]'],
                    innerWrapper: ['bg-transparent', 'rounded-[100px]'],
                    inputWrapper: ['bg-gray-50 h-[33px] min-h-[33px] px-1', 'rounded-[8px]'],
                  }}
                />
              )}
            </div>
            {item[0] && RenderItemFollowType(item[0])}
          </div>
          <div className="w-1/2">
            <div className="mb-2 flex items-center justify-between">
              <div className="flex items-center">
                <span className={`text-14 font-semibold sm:text-12 ${classLabel}`}>
                  {item[1]?.label}
                </span>
                {item[1]?.tooltip && (
                  <TooltipCustom
                    showArrow
                    content={
                      <span className="max-w-[370px] text-13 font-normal text-white">
                        {item[1]?.tooltip}
                      </span>
                    }
                    classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                  >
                    <span className="ml-2">
                      <InfoGenIcon />
                    </span>
                  </TooltipCustom>
                )}
              </div>
              {item[1]?.type === 'range' && (
                <Input
                  // required
                  // min={validate.min}
                  // max={validate.max}
                  step={item[1].step}
                  value={watch(item[1]?.key)}
                  defaultValue={watch(item[1]?.key)}
                  onChange={(e) => item[1]?.key && setValue(item[1]?.key, e.target.value)}
                  type="number"
                  classNames={{
                    base: 'w-[30%]',
                    input: ['font-normal sm:text-12 text-14 pl-1', 'rounded-[100px]'],
                    innerWrapper: ['bg-transparent', 'rounded-[100px]'],
                    inputWrapper: ['bg-gray-50 h-[33px] min-h-[33px] px-1', 'rounded-[8px]'],
                  }}
                />
              )}
            </div>
            {item[1] && RenderItemFollowType(item[1])}
          </div>
        </div>
      ) : (
        <>
          <div className="mb-2 flex items-center justify-between">
            <div className="flex items-center">
              <span className={`text-14 font-semibold sm:text-12 ${classLabel}`}>{item.label}</span>
              {item.tooltip && (
                <TooltipCustom
                  showArrow
                  content={
                    <span className="max-w-[370px] text-13 font-normal text-white">
                      {item.tooltip}
                    </span>
                  }
                  classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                >
                  <span className="ml-2">
                    <InfoGenIcon />
                  </span>
                </TooltipCustom>
              )}
            </div>
            {item.type === 'range' && (
              <Input
                // required
                // min={validate.min}
                // max={validate.max}
                step={item.step}
                value={watch(item?.key)}
                defaultValue={watch(item?.key)}
                onChange={(e) => item?.key && setValue(item?.key, e.target.value)}
                type="number"
                classNames={{
                  base: 'w-[30%]',
                  input: ['font-normal sm:text-12 text-14 pl-1', 'rounded-[100px]'],
                  innerWrapper: ['bg-transparent', 'rounded-[100px]'],
                  inputWrapper: ['bg-gray-50 h-[33px] min-h-[33px] px-1', 'rounded-[8px]'],
                }}
              />
            )}
          </div>
          {RenderItemFollowType(item)}
        </>
      )}
    </>
  );
};

export default FieldItemForm;
