import React from 'react';

import { IconDownloadWhite } from '@/assets';
import { downloadImage } from '@/utils';

const NsfwMark: React.FC<{
  url: string;
  zIndex?: number;
  className?: string;
  absolute?: boolean;
}> = ({ url, zIndex = 10, className = '', absolute = true }) => {
  const handleDownload = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    await downloadImage(url, 'artwork');
  };

  return (
    <div
      style={{ zIndex }}
      className={`left-0 top-0 h-full w-full backdrop-blur-[25px] ${absolute ? 'absolute' : ''} ${
        className ? className : ''
      }`}
    >
      <div className="absolute left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.2)]" />
      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
        <p className="max-w-[160px] text-center text-[13px] text-white">
          This artwork is marked as NSFW
        </p>
        <div
          onClick={handleDownload}
          className="mt-2 flex h-[28px] cursor-pointer items-center rounded-[100px] bg-[rgba(255,255,255,0.24)] px-[10px] text-[13px] font-medium text-white"
        >
          <IconDownloadWhite className="mr-1 scale-80" /> Download
        </div>
      </div>
    </div>
  );
};

export default NsfwMark;
